<template>
  <v-container>
    <div class="cart-recap-page">
      <h1>{{ $t("selfScanning.recapPage.orderDetails") }}</h1>
      <div>
        <span>{{ $t("selfScanning.recapPage.total") }} </span>
        <span>{{ $n(cartSSC.grossTotal, "currency") }}</span>
      </div>
      <div>
        <span>{{ $t("selfScanning.recapPage.totalProducts") }} </span
        ><span>{{ cartSSC.totalItems }}</span>
      </div>
      <div>
        <span>{{ $t("selfScanning.recapPage.youAreSaving") }} </span
        ><span>{{ $n(cartSSC.totalDiscount, "currency") }}</span>
      </div>
      <h1>{{ $t("selfScanning.recapPage.customerDetail") }}</h1>
      <div>
        <span>{{ $t("selfScanning.recapPage.name") }} </span
        ><span>{{ cartSSC.user.firstName }} {{ cartSSC.user.firstName }}</span>
      </div>
      <div>
        <span>{{ $t("selfScanning.recapPage.card") }} </span
        ><span>{{ cartSSC.user.fidelityCard }}</span>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.cart-recap-page {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: var(--v-grey-darken2);
  h1 {
    font-size: 26px;
    color: var(--v-primary-base);
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CartRecapPage",
  mixins: [],
  props: {},
  computed: {
    ...mapGetters({
      cartSSC: "custom/getCartSSC"
    })
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
